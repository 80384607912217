import { createStore } from "vuex";

frappe.provide("frappe.views");

(function () {
	var method_prefix = "frappe.desk.doctype.kanban_board.kanban_board.";
	var ioi_method_prefix = "silicon_ioi.ioi_configuration.kanban.ioi_kanban.";

	let columns_unwatcher = null;

	var store = createStore({
		state: {
			doctype: "",
			board: {},
			card_meta: {},
			cards: [],
			columns: [],
			filters_modified: false,
			cur_list: {},
			empty_state: true,
		},
		mutations: {
			update_state(state, obj) {
				Object.assign(state, obj);
			},
		},
		actions: {
			init: function (context, opts) {
				context.commit("update_state", {
					empty_state: true,
				});
				var board = opts.board;
				var card_meta = opts.card_meta;
				opts.card_meta = card_meta;
				opts.board = board;
				var cards = opts.cards.map(function (card) {
					return prepare_card(card, opts);
				});
				var columns = prepare_columns(board.columns);
				context.commit("update_state", {
					doctype: opts.doctype,
					board: board,
					card_meta: card_meta,
					cards: cards,
					columns: columns,
					cur_list: opts.cur_list,
					empty_state: false,
					wrapper: opts.wrapper,
				});
			},
			load_card_fields(context, doctype) {
				return new Promise((resolve) => {
					frappe.call({
						method: "silicon_ioi.ioi_configuration.kanban.ioi_kanban.load_card_fields",
						args: {
							doctype: doctype,
							fields: cur_list.board.fields,
						},
						callback: function (r) {
							resolve(cur_list.board.formatted_fields = r.message);
						},
					});
				});
			},
			load_userstatus_status_colors(context, doctype) {
				let show_status = cur_list.current_profile.show_status
				let show_user_status = cur_list.current_profile.show_user_status

				if (show_status == 1 || show_user_status == 1) {
					return new Promise((resolve) => {
						frappe.call({
							method: "silicon_ioi.ioi_configuration.kanban.ioi_kanban.load_userstatus_status_colors",
							args: {
								doctype: doctype,
								show_status: show_status,
								show_user_status: show_user_status,
							},
							callback: function (r) {
								resolve(cur_list.board.userstatus_status_colors = r.message);
							},
						});
					});
				} else {
					cur_list.board.userstatus_status_colors = []
				}
			},
			load_colored_field(context, doctype) {
				let show_colored_field = cur_list.current_profile.show_colored_field

				if (show_colored_field == 1) {
					return new Promise((resolve) => {
						frappe.call({
							method: "silicon_ioi.ioi_configuration.kanban.ioi_kanban.load_colored_field",
							args: {
								doctype: doctype,
								profile_name: cur_list.current_profile.name,
							},
							callback: function (r) {
								let colored_field = r.message

								let field_to_compare = colored_field.field_to_compare
								let system_field = colored_field.system_field
								let value = colored_field.value

								// Update object to get only value to compare

								// Field
								if (!field_to_compare || field_to_compare == '') {
									delete colored_field.field_to_compare;
								} else {
									colored_field.value_to_compare = field_to_compare.match(/\((.*?)\)/)[1];
									colored_field.field_type = "field";
									delete colored_field.field_to_compare;
								}

								// System
								if (!system_field || system_field == '') {
									delete colored_field.system_field;
								} else {
									// Format system field
									if (system_field === __('System current date')) {
										colored_field.value_to_compare = frappe.datetime.get_today()
									} else if (system_field === __('System user')) {
										colored_field.value_to_compare = frappe.session.user
									}
									delete colored_field.system_field;
								}

								// Value
								if (!value || value == '') {
									delete colored_field.value;
								} else {
									colored_field.value_to_compare = value
									delete colored_field.value;
								}

								resolve(cur_list.board.colored_field = colored_field);
							},
						});
					});
				}
			},
			load_system_constraints(context, doctype) {
				return new Promise((resolve) => {
					frappe.call({
						method: "silicon_ioi.ioi_configuration.kanban.ioi_kanban.load_module_constraints",
						args: {
							doctype: doctype,
						},
						callback: function (r) {
							if (r.message.length) {
								resolve(cur_list.board.system_constraints = r.message);
							} else {
								resolve();
							}
						}
					})
				})
			},
			update_cards: function (context, cards) {
				var state = context.state;
				var _cards = cards
					.map((card) => prepare_card(card, state))
					.concat(state.cards)
					.uniqBy((card) => card.name);

				context.commit("update_state", {
					cards: _cards,
				});
			},
			add_column: function (context, col) {
				if (frappe.model.can_create("Custom Field")) {
					store.dispatch("update_column", { col, action: "add" });
				} else {
					frappe.msgprint({
						title: __("Not permitted"),
						message: __("You are not allowed to create columns"),
						indicator: "red",
					});
				}
			},
			archive_column: function (context, col) {
				store.dispatch("update_column", { col, action: "archive" });
			},
			restore_column: function (context, col) {
				store.dispatch("update_column", { col, action: "restore" });
			},
			update_column: function (context, { col, action }) {
				var doctype = context.state.doctype;
				var board = context.state.board;
				fetch_customization(doctype)
					.then(function (doc) {
						return modify_column_field_in_c11n(doc, board, col.title, action);
					})
					.then(save_customization)
					.then(function () {
						return update_kanban_board(board.name, col.title, action);
					})
					.then(
						function (r) {
							var cols = r.message;
							context.commit("update_state", {
								columns: prepare_columns(cols),
							});
						},
						function (err) {
							console.error(err); // eslint-disable-line
						}
					);
			},
			add_card: function (context, { card_title, column_title }) {
				var state = context.state;
				var doc = frappe.model.get_new_doc(state.doctype);
				var field = state.card_meta.title_field;
				var quick_entry = state.card_meta.quick_entry;

				var doc_fields = {};
				doc_fields[field.fieldname] = card_title;
				doc_fields[state.board.field_name] = column_title;
				state.cur_list.filter_area.get().forEach(function (f) {
					if (f[2] !== "=") return;
					doc_fields[f[1]] = f[3];
				});

				$.extend(doc, doc_fields);

				// add the card directly
				// for better ux
				const card = prepare_card(doc, state);
				card._disable_click = true;
				const cards = [...state.cards, card];
				// remember the name which we will override later
				const old_name = doc.name;
				context.commit("update_state", { cards });

				if (field && !quick_entry) {
					return insert_doc(doc).then(function (r) {
						// update the card in place with the updated doc
						const updated_doc = r.message;
						const index = state.cards.findIndex((card) => card.name === old_name);
						const card = prepare_card(updated_doc, state);
						const new_cards = state.cards.slice();
						new_cards[index] = card;
						context.commit("update_state", { cards: new_cards });
						const args = {
							new: 1,
							name: card.name,
							colname: updated_doc[state.board.field_name],
						};
						store.dispatch("update_order_for_single_card", args);
					});
				} else {
					frappe.new_doc(state.doctype, doc);
				}
			},
			update_card: function (context, card) {
				var index = -1;
				context.state.cards.forEach(function (c, i) {
					if (c.name === card.name) {
						index = i;
					}
				});
				var cards = context.state.cards.slice();
				if (index !== -1) {
					cards.splice(index, 1, card);
				}
				context.commit("update_state", { cards: cards });
			},
			update_order_for_single_card: function (context, card) {
				// cache original order
				const _cards = context.state.cards.slice();
				const _columns = context.state.columns.slice();
				let args = {};
				let method_name = "";

				if (card.new) {
					method_name = "add_card";
					args = {
						board_name: context.state.board.name,
						docname: card.name,
						colname: card.colname,
					};
				} else {
					method_name = "update_order_for_single_card";
					args = {
						board_name: context.state.board.name,
						docname: card.name,
						from_colname: card.from_colname,
						to_colname: card.to_colname,
						old_index: card.old_index,
						new_index: card.new_index,
					};
				}
				frappe.dom.freeze();

				frappe
					.call({
						method: method_prefix + method_name,
						args: args,
						callback: (r) => {
							let board = r.message.board
							let is_allowed = r.message.is_allowed
							let updated_cards = []

							if (is_allowed == false) {
								updated_cards = [
									{ name: card.name, column: card.from_colname || card.colname },
								];
							} else if (is_allowed == true) {
								updated_cards = [
								{ name: card.name, column: card.to_colname || card.colname },
							];

								if (cur_list.current_profile) {
									// Update selected field
									let card_field_to_update = cur_list.board.formatted_fields.find(card => card.name === updated_cards[0].name)

									if (card_field_to_update && Object.keys(card_field_to_update).includes(board.field_name)) {
										if (updated_cards[0].column != "UNDEFINED" && card_field_to_update[board.field_name] !== undefined) {
											card_field_to_update[board.field_name].value = updated_cards[0].column
										} else if (updated_cards[0].column === "UNDEFINED") {
											card_field_to_update[board.field_name].value = null
										}
									}
								}
							}

							let cards = update_cards_column(updated_cards);
							let columns = prepare_columns(board.columns);
							context.commit("update_state", {
								cards: cards,
								columns: columns,
							});
							frappe.dom.unfreeze();
						},
					})
					.fail(function () {
						// revert original order
						context.commit("update_state", {
							cards: _cards,
							columns: _columns,
						});
						frappe.dom.unfreeze();
					});
			},
			update_order: function (context) {
				// cache original order
				const _cards = context.state.cards.slice();
				const _columns = context.state.columns.slice();

				const order = {};
				context.state.wrapper.find(".kanban-column[data-column-value]").each(function () {
					var col_name = $(this).data().columnValue;
					order[col_name] = [];
					$(this)
						.find(".kanban-card-wrapper")
						.each(function () {
							var card_name = decodeURIComponent($(this).data().name);
							order[col_name].push(card_name);
						});
				});

				frappe
					.call({
						method: method_prefix + "update_order",
						args: {
							board_name: context.state.board.name,
							order: order,
						},
						callback: (r) => {
							var board = r.message[0];
							var updated_cards = r.message[1];
							var cards = update_cards_column(updated_cards);
							var columns = prepare_columns(board.columns);
							context.commit("update_state", {
								cards: cards,
								columns: columns,
							});
						},
					})
					.fail(function () {
						// revert original order
						context.commit("update_state", {
							cards: _cards,
							columns: _columns,
						});
					});
			},
			update_column_order: function (context, order) {
				return frappe
					.call({
						method: ioi_method_prefix + "update_column_order",
						args: {
							board_name: context.state.board.name,
							order: order,
						},
					})
					.then(function (r) {
						var board = r.message;
						var columns = prepare_columns(board.columns);
						context.commit("update_state", {
							columns: columns,
						});
					});
			},
			set_indicator: function (context, { column, color }) {
				return frappe
					.call({
						method: method_prefix + "set_indicator",
						args: {
							board_name: context.state.board.name,
							column_name: column.title,
							indicator: color,
						},
					})
					.then(function (r) {
						var board = r.message;
						var columns = prepare_columns(board.columns);
						context.commit("update_state", {
							columns: columns,
						});
					});
			},
		},
	});

	frappe.views.KanbanBoard = function (opts) {
		var self = {};
		self.wrapper = opts.wrapper;
		self.cur_list = opts.cur_list;
		self.board_name = opts.board_name;

		self.update = function (cards) {
			// update cards internally
			opts.cards = cards;

			if (self.wrapper.find(".kanban").length > 0 && self.cur_list.start !== 0) {
				store.dispatch("update_cards", cards);
			} else {
				init();
			}
		};

		async function init() {
			if (cur_list.current_profile) {
				await store.dispatch('load_card_fields', opts.doctype)
				await store.dispatch('load_userstatus_status_colors', opts.doctype)
				await store.dispatch('load_colored_field', opts.doctype)
			}
			if (cur_list.board.field_name === "ioistatus") await store.dispatch('load_system_constraints', opts.doctype);
			store.dispatch("init", opts);
			columns_unwatcher && columns_unwatcher();
			store.watch((state, getters) => {
				return state.columns;
			}, make_columns);
			prepare();
			make_columns();
			store.watch((state, getters) => {
				return state.cur_list;
			}, setup_restore_columns);
			columns_unwatcher = store.watch((state, getters) => {
				return state.columns;
			}, setup_restore_columns);
			store.watch((state, getters) => {
				return state.empty_state;
			}, show_empty_state);
			store.dispatch("update_order");
		}

		function prepare() {
			self.$kanban_board = self.wrapper.find(".kanban");

			if (self.$kanban_board.length === 0) {
				self.$kanban_board = $(frappe.render_template("kanban_board"));
				self.$kanban_board.appendTo(self.wrapper);
			}

			self.$filter_area = self.cur_list.$page.find(".active-tag-filters");
			bind_events();
			setup_sortable();
		}

		function make_columns() {
			// self.$kanban_board.find(".kanban-column").not(".add-new-column").remove();
			self.$kanban_board.find(".kanban-column").remove();
			var columns = store.state.columns;

			columns.filter(is_active_column).map(function (col) {
				frappe.views.KanbanBoardColumn(col, self.$kanban_board);
			});
		}

		function bind_events() {
			//bind_add_column();
			bind_clickdrag();
		}

		function setup_sortable() {
			var sortable = new Sortable(self.$kanban_board.get(0), {
				group: "columns",
				animation: 150,
				dataIdAttr: "data-column-value",
				filter: ".add-new-column",
				handle: ".kanban-column-title",
				onEnd: function () {
					var order = sortable.toArray();
					order = order.slice(1);
					store.dispatch("update_column_order", order);
				},
				disabled: this.cur_list.settings.kanban.read_only
			});
		}

		function bind_add_column() {
			//
		}

		function bind_clickdrag() {
			let isDown = false;
			let startX;
			let scrollLeft;
			let draggable = self.$kanban_board[0];

			draggable.addEventListener("mousedown", (e) => {
				// don't trigger scroll if one of the ancestors of the
				// clicked element matches any of these selectors
				let ignoreEl = [
					".kanban-column .kanban-column-header",
					".kanban-column .add-card",
					".kanban-column .kanban-card.new-card-area",
					".kanban-card-wrapper",
				];
				if (ignoreEl.some((el) => e.target.closest(el))) return;

				isDown = true;
				draggable.classList.add("clickdrag-active");
				startX = e.pageX - draggable.offsetLeft;
				scrollLeft = draggable.scrollLeft;
			});
			draggable.addEventListener("mouseleave", () => {
				isDown = false;
				draggable.classList.remove("clickdrag-active");
			});
			draggable.addEventListener("mouseup", () => {
				isDown = false;
				draggable.classList.remove("clickdrag-active");
			});
			draggable.addEventListener("mousemove", (e) => {
				if (!isDown) return;
				e.preventDefault();
				const x = e.pageX - draggable.offsetLeft;
				const walk = x - startX;
				draggable.scrollLeft = scrollLeft - walk;
			});
		}

		function setup_restore_columns() {
			var cur_list = store.state.cur_list;
			var columns = store.state.columns;
			var list_row_right = cur_list.$page
				.find(`[data-list-renderer='Kanban'] .list-row-right`)
				.css("margin-right", "15px");
			list_row_right.empty();

			var archived_columns = columns.filter(function (col) {
				return col.status === "Archived";
			});

			if (!archived_columns.length) return;

			var options = archived_columns.reduce(function (a, b) {
				return (
					a +
					`<li><a class='option'>" +
					"<span class='ellipsis' style='max-width: 100px; display: inline-block'>" +
					__(b.title) + "</span>" +
					"<button style='float:right;' data-column='" + b.title +
					"' class='btn btn-default btn-xs restore-column text-muted'>"
					+ __('Restore') + "</button></a></li>`
				);
			}, "");

			var $dropdown = $(
				"<div class='dropdown pull-right'>" +
					"<a class='text-muted dropdown-toggle' data-toggle='dropdown'>" +
					"<span class='dropdown-text'>" +
					__("Archived Columns") +
					"</span><i class='caret'></i></a>" +
					"<ul class='dropdown-menu'>" +
					options +
					"</ul>" +
					"</div>"
			);

			list_row_right.html($dropdown);

			$dropdown.find(".dropdown-menu").on("click", "button.restore-column", function () {
				var column_title = $(this).data().column;
				var col = {
					title: column_title,
					status: "Archived",
				};
				store.dispatch("restore_column", col);
			});
		}

		function show_empty_state() {
			var empty_state = store.state.empty_state;

			if (empty_state) {
				self.$kanban_board.find(".kanban-column").hide();
				self.$kanban_board.find(".kanban-empty-state").show();
			} else {
				self.$kanban_board.find(".kanban-column").show();
				self.$kanban_board.find(".kanban-empty-state").hide();
			}
		}

		init();

		return self;
	};

	frappe.views.KanbanBoardColumn = function (column, wrapper) {
		var self = {};
		var filtered_cards = [];

		function init() {
			make_dom();
			setup_sortable();
			make_cards();
			store.watch((state, getters) => {
				return state.cards;
			}, make_cards);
			bind_add_card();
			bind_options();
		}

		function make_dom() {
			self.$kanban_column = $(
				frappe.render_template("ioi_kanban_column", {
					title: __(column.label), // ou appel d'une fonction client: get_label_from_name
					value: column.title,
					doctype: store.state.doctype,
					indicator: frappe.scrub(column.indicator, "-"),
				})
			).appendTo(wrapper);

			if (cur_list.board.field_name === "ioistatus" || cur_list.board.field_name === "ioiuserstatus") {
				self.$kanban_column.find(".indicator-pill")[0].style.backgroundColor = column.color;
			}
			
			self.$kanban_cards = self.$kanban_column.find(".kanban-cards");
		}

		function make_cards() {
			self.$kanban_cards.empty();
			var cards = store.state.cards;
			filtered_cards = get_cards_for_column(cards, column);
			var filtered_cards_names = filtered_cards.map((card) => card.name);

			var order = column.order;
			if (order) {
				order = JSON.parse(order);
				// new cards
				filtered_cards.forEach(function (card) {
					if (order.indexOf(card.name) === -1) {
						frappe.views.KanbanBoardCard(card, self.$kanban_cards);
					}
				});
				order.forEach(function (name) {
					if (!filtered_cards_names.includes(name)) return;
					frappe.views.KanbanBoardCard(get_card(name), self.$kanban_cards);
				});
			} else {
				filtered_cards.map(function (card) {
					frappe.views.KanbanBoardCard(card, self.$kanban_cards);
				});
			}
		}

		function setup_sortable() {
			Sortable.create(self.$kanban_cards.get(0), {
				group: "cards",
				animation: 150,
				dataIdAttr: "data-name",
				forceFallback: true,
				filter: '.disable-click',
				onStart: function () {
					wrapper.find(".kanban-cards").height("100vh");
				},
				onMove: function (e) {
					if (cur_list.board.system_constraints) {
						let from_col = e.from.parentElement.getAttribute('data-column-value')
						let to_col = e.to.parentElement.getAttribute('data-column-value')

						let constraint = cur_list.board.system_constraints.find(val => val.from_status == from_col && val.to_status == to_col)

						if (constraint) {
							if (!e.to.parentElement.classList.contains('disable-click')) {
								e.to.parentElement.classList.add('disable-click')
							}
						} else {
							if (e.to.parentElement.classList.contains('disable-click')) {
								e.to.parentElement.classList.remove('disable-click')
							}
						}
					}
				},
				onEnd: function (e) {
					wrapper.find(".kanban-cards").height("auto");
					// update order
					const args = {
						name: decodeURIComponent($(e.item).attr("data-name")),
						from_colname: $(e.from)
							.parents(".kanban-column")
							.attr("data-column-value"),
						to_colname: $(e.to).parents(".kanban-column").attr("data-column-value"),
						old_index: e.oldIndex,
						new_index: e.newIndex,
					};
					store.dispatch("update_order_for_single_card", args);
				},
				onAdd: function () {},
				disabled: this.cur_list.settings.kanban.read_only
			});
		}

		function bind_add_card() {
			//
		}

		function bind_options() {
			self.$kanban_column
				.find(".column-options .dropdown-menu")
				.on("click", "[data-action]", function () {
					var $btn = $(this);
					var action = $btn.data().action;

					if (action === "archive") {
						store.dispatch("archive_column", column);
					} else if (action === "indicator") {
						var color = $btn.data().indicator;
						store.dispatch("set_indicator", { column, color });
					}
				});
				if (cur_list.board.field_name !== "ioistatus" && cur_list.board.field_name !== "ioiuserstatus") {
					get_column_indicators(function (indicators) {
						let html = `<li class="button-group">${indicators
							.map((indicator) => {
								let classname = frappe.scrub(indicator, "-");
								return `<div data-action="indicator" data-indicator="${indicator}" class="btn btn-default btn-xs indicator-pill ${classname}"></div>`;
							})
							.join("")}</li>`;
						self.$kanban_column.find(".column-options .dropdown-menu").append(html);
					});
				}
		}

		init();
	};

	frappe.views.KanbanBoardCard = function (card, wrapper) {
		var self = {};

		function init() {
			if (!card) return;
			make_dom();
			if (cur_list.current_profile && cur_list.board.userstatus_status_colors.length) {
				render_card_pills();
			}
			render_card_meta();
		}

		function make_dom() {
			var opts = {
				name: card.name,
				title: frappe.utils.html2text(card.title),
				disable_click: card.allow_changes == undefined || card.allow_changes ? "" : "disable-click",
				creation: card.creation,
				doc_content: get_doc_content(card),
				image_url: cur_list.get_image_url(card),
				form_link: frappe.utils.get_form_link(card.doctype, card.name),
			};

			self.$card = $(frappe.render_template("kanban_card", opts)).appendTo(wrapper);
		}

		function render_card_pills() {
			let card_values = cur_list.board.formatted_fields.find(obj => obj.name === card.name)

			let status_color = cur_list.board.userstatus_status_colors[0].find(obj => obj.name.includes((card_values['ioistatus'].value).replace(/\n/g, '')))
			let userstatus_color = cur_list.board.userstatus_status_colors[1].find(obj => obj.name.includes((card_values['ioiuserstatus'].value).replace(/\n/g, '')))

			self.$card.find(".kanban-card-body")[0].insertAdjacentHTML("afterbegin", `
				<div class="d-flex justify-content-${status_color && userstatus_color ? 'between' : status_color ? 'end' : userstatus_color ? 'start' : ''} align-items-center" style="padding-bottom: 12px">
					${ userstatus_color ? `<div class="rounded" style="height: 19.5px; width: 19.5px; border: 1px solid var(--btn-group-border-color); background-color: ${ userstatus_color.background_color }"></div>` : '' }
					${ status_color ? `<div class="rounded-circle" style="height: 21px; width: 21px; border: 1px solid var(--btn-group-border-color); background-color: ${ status_color.background_color }"></div>` : '' }
				</div>
			`);
		}

		function get_doc_content(card) {
			if (cur_list.current_profile) {
				let rendered_fields = [];
				let fields_to_display = cur_list.board.formatted_fields.find(el => el && el.name === card.name)
				let non_displayable_fields = ["name", "ioistatus", "ioiuserstatus"]

				// Set colored field settings
				let colored_field_obj = cur_list.board.colored_field

				let bg_color = ''
				let field_to_color_label = ''

				if (colored_field_obj && colored_field_obj.value_to_compare) {
					let regex = /\((.*?)\)/ // get fieldname only

					let field_to_color = fields_to_display[colored_field_obj.field_to_color.match(regex)[1]]
					field_to_color_label = field_to_color.label

					let field_to_test_fn = colored_field_obj.testing_field.match(regex)[1]
					let field_to_test = fields_to_display[field_to_test_fn]

					if (!colored_field_obj.selected_fields.split('\n').find(field => field.match(regex) && field.match(regex)[1] == field_to_test_fn)) {
						non_displayable_fields.push(field_to_test_fn)
					}

					let value_to_compare = ''

					// Get field value if it's a field to compare
					if (colored_field_obj.field_type && colored_field_obj.field_type === "field") {
						if (fields_to_display[colored_field_obj.value_to_compare]) {
							value_to_compare = fields_to_display[colored_field_obj.value_to_compare].value
						}
					}

					if (value_to_compare === '') {
						value_to_compare = colored_field_obj.value_to_compare
					}

					if (field_to_test) {
						switch (true) {
							case field_to_test.value < value_to_compare:
								bg_color = colored_field_obj.color_lower;
								break;
							case field_to_test.value == value_to_compare:
								bg_color = colored_field_obj.color_equal;
								break;
							case field_to_test.value > value_to_compare:
								bg_color = colored_field_obj.color_upper;
								break;
						}
					}
				}

				if (fields_to_display) {
					Object.keys(fields_to_display).forEach(function (key, index) {
						if (!non_displayable_fields.includes(key)) {
							let label = fields_to_display[key].label;
							let value = fields_to_display[key].value;
							if (value != null && value != '') {
								if (bg_color !== '' && label === field_to_color_label) {
									rendered_fields.push(`
										<div class="text-muted pl-1" style="background-color: ${bg_color}; border-radius: var(--border-radius);">
											${label} : 
											<span>${value}</span>
										</div>
									`);
								} else {
									rendered_fields.push(`
										<div class="text-muted pl-1">
											${label} : 
											<span>${value}</span>
										</div>
									`);
								}
							}
						}
					})
				}

				return rendered_fields.join("");
			}
		}

		function get_tags_html(card) {
			return card.tags
				? `<div class="kanban-tags">
					${cur_list.get_tags_html(card.tags, 3, true)}
				</div>`
				: "";
		}

		function render_card_meta() {
			let html = get_tags_html(card);

			if (card.comment_count > 0)
				html += `<span class="list-comment-count small text-muted ">
					${frappe.utils.icon("small-message")}
					${card.comment_count}
				</span>`;

			const $assignees_group = get_assignees_group();

			html += `
				<span class="kanban-assignments"></span>
				${cur_list.get_like_html(card)}
			`;

			if (card.color && frappe.ui.color.validate_hex(card.color)) {
				const $div = $("<div>");
				$("<div></div>")
					.css({
						width: "30px",
						height: "4px",
						borderRadius: "2px",
						marginBottom: "8px",
						backgroundColor: card.color,
					})
					.appendTo($div);

				self.$card.find(".kanban-card .kanban-title-area").prepend($div);
			}

			self.$card
				.find(".kanban-card-meta")
				.empty()
				.append(html)
				.find(".kanban-assignments")
				.append($assignees_group);
		}

		function get_assignees_group() {
			return frappe.avatar_group(card.assigned_list, 3, {
				css_class: "avatar avatar-small",
				action_icon: "add",
				action: show_assign_to_dialog,
			});
		}

		function show_assign_to_dialog(e) {
			e.preventDefault();
			e.stopPropagation();
			self.assign_to = new frappe.ui.form.AssignToDialog({
				obj: self,
				method: "frappe.desk.form.assign_to.add",
				doctype: card.doctype,
				docname: card.name,
				callback: function () {
					const users = self.assign_to_dialog.get_values().assign_to;
					card.assigned_list = [...new Set(card.assigned_list.concat(users))];
					store.dispatch("update_card", card);
				},
			});
			self.assign_to_dialog = self.assign_to.dialog;
			self.assign_to_dialog.show();
		}

		init();
	};

	function prepare_card(card, state, doc) {
		var assigned_list = card._assign ? JSON.parse(card._assign) : [];
		var comment_count = card._comment_count || 0;

		if (doc) {
			card = Object.assign({}, card, doc);
		}

		return {
			doctype: state.doctype,
			name: card.name,
			title: card[state.card_meta.title_field.fieldname],
			creation: moment(card.creation).format("MMM DD, YYYY"),
			_liked_by: card._liked_by,
			image: card[cur_list.meta.image_field],
			tags: card._user_tags,
			column: card[state.board.field_name],
			assigned_list: card.assigned_list || assigned_list,
			comment_count: card.comment_count || comment_count,
			color: card.color || null,
			doc: doc || card,
		};
	}

	function prepare_columns(columns) {
		return columns.map(function (col) {
			return {
				title: col.column_name,
				label: col.title,
				status: col.status,
				order: col.order,
				color: col.color,
				indicator: col.indicator || "gray",
			};
		});
	}

	function modify_column_field_in_c11n(doc, board, title, action) {
		doc.fields.forEach(function (df) {
			if (df.fieldname === board.field_name && df.fieldtype === "Select") {
				if (!df.options) df.options = "";

				if (action === "add") {
					//add column_name to Select field's option field
					if (!df.options.includes(title)) df.options += "\n" + title;
				} else if (action === "delete") {
					var options = df.options.split("\n");
					var index = options.indexOf(title);
					if (index !== -1) options.splice(index, 1);
					df.options = options.join("\n");
				}
			}
		});
		return doc;
	}

	function fetch_customization(doctype) {
		return new Promise(function (resolve) {
			frappe.model.with_doc("Customize Form", "Customize Form", function () {
				var doc = frappe.get_doc("Customize Form");
				doc.doc_type = doctype;
				frappe.call({
					doc: doc,
					method: "fetch_to_customize",
					callback: function (r) {
						resolve(r.docs[0]);
					},
				});
			});
		});
	}

	function save_customization(doc) {
		if (!doc) return;
		doc.hide_success = true;
		return frappe.call({
			doc: doc,
			method: "save_customization",
		});
	}

	function insert_doc(doc) {
		return frappe.call({
			method: "frappe.client.insert",
			args: {
				doc: doc,
			},
			callback: function () {
				frappe.model.clear_doc(doc.doctype, doc.name);
				frappe.show_alert({ message: __("Saved"), indicator: "green" }, 1);
			},
		});
	}

	function update_kanban_board(board_name, column_title, action) {
		var method;
		var args = {
			board_name: board_name,
			column_title: column_title,
		};
		if (action === "add") {
			method = "add_column";
		} else if (action === "archive" || action === "restore") {
			method = "archive_restore_column";
			args.status = action === "archive" ? "Archived" : "Active";
		}
		return frappe.call({
			method: method_prefix + method,
			args: args,
		});
	}

	function is_active_column(col) {
		return col.status !== "Archived";
	}

	function get_cards_for_column(cards, column) {
		let cards_with_no_value = cards.filter(function (card) {
			return card.column == null || card.column == '' || card.column == 'UNDEFINED';
		});

		let cards_with_value = cards.filter(function (card) {
			return card.column === column.title;
		});

		if (column.title === "UNDEFINED") {
			return cards_with_no_value
		} else {
			return cards_with_value
		}
	}

	function get_card(name) {
		return store.state.cards.find(function (c) {
			return c.name === name;
		});
	}

	function update_cards_column(updated_cards) {
		var cards = store.state.cards;
		cards.forEach(function (c) {
			updated_cards.forEach(function (uc) {
				if (uc.name === c.name) {
					c.column = uc.column;
				}
			});
		});
		return cards;
	}

	function get_column_indicators(callback) {
		frappe.model.with_doctype("Kanban Board Column", function () {
			var meta = frappe.get_meta("Kanban Board Column");
			var indicators;
			meta.fields.forEach(function (df) {
				if (df.fieldname === "indicator") {
					indicators = df.options.split("\n");
				}
			});
			if (!indicators) {
				//
				indicators = ["green", "blue", "orange", "gray"];
			}
			callback(indicators);
		});
	}
})();
